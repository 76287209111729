import React                 from 'react';
import { Button, Flex, Box } from '@chakra-ui/react';
import { graphql }           from 'gatsby';
import Img                   from 'gatsby-image';

import Wrapper  from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import SEO      from '@interness/web-core/src/components/modules/SEO/SEO';
import Link     from '@interness/web-core/src/components/elements/Link/Link';
import Spacer   from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Carousel from '@interness/web-core/src/components/media/Carousel/Carousel';


const GutscheinePage = ({ data }) => {
  return (
    <>
      <SEO title="Gutscheine" description="Bei uns erhalten Sie Gutscheine mit dem Wert Ihrer Wahl."/>
      <Wrapper>
        <Spacer/>
        <h1>Gutscheine</h1>
        <section>
          <Flex flexWrap="wrap" flexDirection={['column-reverse', 'row', 'row', 'row']}>
            <Box width={['100%', '50%', '50%', '50%']} textAlign='justify' pr={[0, 12, 12, 12]} mb={12}>
              <p>Sie möchten Ihren Liebsten eine Freude bereiten?</p>

              <p>Wenn Sie sich bei der Auswahl eines Geschenkes nicht ganz sicher sind, so lassen Sie doch den
                Glücklichen
                sein Lieblingsstück selbst auswählen.
                Bei uns erhalten Sie Gutscheine mit dem Wert Ihrer Wahl.
                Gern verpacken wir Ihre Gutschein liebevoll zur Abholung bei uns im Geschäft oder senden Ihnen den
                Gutschein
                zu.</p>

              <p>Alternativ können Sie auf den Link unten klicken. Wählen Sie hier einen Wunschbetrag und folgen dann
                einfach den weiteren Schritten. Hier können Sie eine Grußbotschaft und ein Bild einfügen. Anschließend
                ausdrucken und verschenken.</p>

              <Button variant={'solid'} target='_blank' rel='noopener noreferrer' colorScheme="brand" isFullWidth as={Link}
                      to="https://www.zmyle.de/co/checkout/1a3jjmhlg07jk49vzljr">Weiter zum Onlinegutschein</Button>
            </Box>
            <Box width={['100%', '50%', '50%', '50%']} mb={4}>
              <Carousel>
                {data.couponImages.media.map((image, i) => {
                  image = image.file;
                  return (
                    <figure key={image.localFile.name}>
                      <Img fluid={image.localFile.childImageSharp.fluid} alt={image.localFile.name}
                           style={{ border: '1px solid lightgray' }} loading={i === 0 ? 'eager' : 'lazy'}/>
                      {image.description &&
                        <figcaption dangerouslySetInnerHTML={{ __html: image.description }}/>}
                    </figure>
                  );
                })}
              </Carousel>
            </Box>
          </Flex>
        </section>
      </Wrapper>
    </>
  )
};

export default GutscheinePage;

export const query = graphql`
    query {
        couponImages: directusMediaCollection(name: {eq: "gutscheine"}) {
            media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 400, maxHeight: 400, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;